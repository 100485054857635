import Litepicker from "litepicker";
import 'litepicker/dist/plugins/keyboardnav';

window.Litepicker = Litepicker;

window.initLitepicker = (el, options = {}) => {

  const litepicker = new Litepicker({
    ...options,
    element: el,
    format: "DD.MM.YYYY",
    plugins: ['keyboardnav'],
    dropdowns: {
      years: true,
      minYear: 1940,
    },
  });

  litepicker.on("selected", () => {
    el.dispatchEvent(new Event("input"));
  });
};

// Example
//
// Age youth = 8 - 12 years
// Age adult = 13+ years
//
// Ticket dateFrom = 1. 1. 2025
//
// -> 
// Youth:
// Valid from: 1. 1. 2013
// Valid to: 31. 12. 2017
//
// Adult:
// Valid from: -
// Valid to: 31. 12. 2012


// window.getTicketMaxDate = (ageGroup, ticketDateFrom) => {
//   const secondsInYear = 60 * 60 * 24 * 365;

//   if(ageGroup === 'youth'){
//     return (ticketDateFrom - MIN_AGE_YOUTH * secondsInYear) * 1000;
//   }

//   if(ageGroup === 'adult'){
//     return (ticketDateFrom - MIN_AGE_ADULT * secondsInYear) * 1000;
//   }
// }

// window.getTicketMinDate = (ageGroup, ticketDateFrom) => {
//   const secondsInYear = 60 * 60 * 24 * 365;

//   if(ageGroup === 'youth'){
//     return (ticketDateFrom - MIN_AGE_ADULT * secondsInYear) * 1000;
//   }

//   if(ageGroup === 'adult'){
//     return null;
//   }
// }